import { render, staticRenderFns } from "./GrantAccess.vue?vue&type=template&id=20c3bb26&scoped=true"
import script from "./GrantAccess.vue?vue&type=script&lang=js"
export * from "./GrantAccess.vue?vue&type=script&lang=js"
import style0 from "./GrantAccess.vue?vue&type=style&index=0&id=20c3bb26&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20c3bb26",
  null
  
)

export default component.exports
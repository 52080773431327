<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <h4>Grant Access</h4>
            </b-col>
          </b-row>

          <b-row class="h-100" v-if="state !== 'show'" align-h="center">
            <b-spinner label="Loading..."></b-spinner>
          </b-row>

          <b-form v-if="state === 'show'" @submit.stop.prevent="submit">
            <div v-if="user==null">
              <b-row>
                <b-col cols="3">
                  <b-row style="display:block;">

                    <b-form-group id="search1-input-group" label="Search" label-for="search">
                      <b-form-input
                          id="search1"
                          name="search1"
                          v-model="search1"
                          @keyup="searchUser"
                      ></b-form-input>
                    </b-form-group>
                  </b-row>


                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">

                  <b-table striped hover
                           sort-icon-left
                           :items="userTableData.dataSource"
                           :fields="userTableData.tableColumns"
                           :busy="userTableData.isLoading"
                           @row-clicked="setUser"
                           :per-page="userTableData.resultsPerPage"
                           id="SearchUser"
                           :current-page="userTableData.currentPage">

                    <template #table-busy>
                      <div class="text-center my-2">
                        <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                      </div>
                    </template>

                  </b-table>
                  <b-row align-h="center">
                    <b-pagination
                        v-model="userTableData.currentPage"
                        :total-rows="userTableRows"
                        :per-page="userTableData.resultsPerPage"
                        aria-controls="SearchUser"
                    ></b-pagination>
                  </b-row>


                </b-col>


              </b-row>
            </div>

            <div v-if="user!=null">
              <b-row>
                <b-col cols="4">
                  <H3>
                    <b-icon icon="arrow-left" @click="goBackToUserSelect"/>
                    {{ user.name }} {{ user.surname }}
                  </H3>
                  <h4>
                    {{ user.email }}
                  </h4>
                </b-col>
                <b-col cols="1"/>
                <b-col cols="3" v-if="selectedEntity!=null">
                  <H3>
                    {{ selectedEntity.name }} ({{ selectedEntity.code }})
                  </H3>
                </b-col>

              </b-row>
              <b-row>
                <b-col cols="4">
                  <b-form-group label="Entity Type">
                    <b-form-radio v-model="entityType" name="entitySelect" value="Customer">Customer</b-form-radio>
                    <b-form-radio v-model="entityType" name="entitySelect" value="Vendor">Vendor</b-form-radio>
                  </b-form-group>
                </b-col>
                <b-col cols="1"></b-col>
                <b-col cols="4" v-if="selectedEntity!=null">
                  <b-form-group id="email-input-group" :label="entityType+' Documents'" label-for="landline">
                    <b-form-checkbox-group
                        id="documentSelect"
                        v-model="accessList[selectedEntity.id]"
                        :options="entityDocumentList"

                        name="documentSelect"
                    ></b-form-checkbox-group>
                  </b-form-group>

                </b-col>
              </b-row>
              <b-row>

                <b-col cols="4">
                  <b-form-group id="passwordConfirm-input-group" :label="entityType+ ' Search'"
                                label-for="passwordConfirm">
                    <b-form-input
                        id="entitySearch"
                        name="entitySearch"
                        v-model="entitySearch"
                        @keyup="searchEntity"
                        type="text"
                    ></b-form-input>

                  </b-form-group>

                </b-col>
              </b-row>


              <b-row>
                <b-col cols="12">

                  <b-table striped hover
                           sort-icon-left
                           :items="entityTableData.dataSource"
                           :fields="entityTableData.tableColumns"
                           :busy="entityTableData.isLoading"
                           @row-clicked="selectEntity"
                           :per-page="entityTableData.resultsPerPage"
                           id="EntityTable"
                           :current-page="entityTableData.currentPage"
                  >

                    <template #table-busy>
                      <div class="text-center my-2">
                        <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                      </div>
                    </template>

                  </b-table>
                  <b-row align-h="center">
                    <b-pagination
                        v-model="entityTableData.currentPage"
                        :total-rows="entityTableRows"
                        :per-page="entityTableData.resultsPerPage"
                        aria-controls="SearchUser"
                    ></b-pagination>
                  </b-row>


                </b-col>


              </b-row>

            </div>
            <hr class="mx-3">
            <b-row>
              <b-col>
                <div class="d-flex justify-content-end">
                  <div>
                    <b-button variant="outline-red" squared @click="goBackToSearch" class="ml-2">Cancel</b-button>
                  </div>
                  <div>
                    <b-button variant="primary" squared @click="submit()" class="ml-2" :disabled="!completed">
                      Save
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-form>

        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<style>
.primary {
  background-color: #ffcb14;
  color: #222222;
}

.primary:hover {
  color: #fff;
}

.page-item.active .page-link {
  z-index: 3;
  color: #222222;
  background-color: #ffcb14;
  border-color: #ffcb14;
}
</style>
<script>
import {mapMutations} from "vuex";
import api from "../../../api";
import {validationMixin} from "vuelidate";

export default {
  mixins: [validationMixin],
  name: "createUser",
  data: () => ({
    state: 'show',
    showError: false,
    errorMessage: "",
    user: null,
    entityType: "Customer",
    selectedDocuments: [],
    documentList: [],
    entityDocumentList: [],
    entitySearch: "",
    entityList: [],
    selectedEntity: null,
    entity: {
      vendor: [],
      customer: [],
    },
    userTableData: {
      resultsPerPage: 5,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Name',
          key: 'name',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Surname',
          key: 'surname',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Email',
          key: 'email',
          sortable: true,
          tdClass: '',
        },
      ]
    },
    entityTableData: {
      resultsPerPage: 5,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Name',
          key: 'name',
          sortable: true,
          tdClass: 'col-9',
        },
        {
          label: 'Code',
          key: 'code',
          sortable: true,
          tdClass: 'col-3',
        }
      ]
    },
    userData: {
      name: null,
      surname: null,
      email: null,
      password: null,
      passwordConfirm: null,
      contactNumber: null,
      vendor: null,
      landline: null,
      roleID: null,
      favoriteIds: null // This enables favorites assigning on user creation in future.

    },
    isLoading: true,
    users: [],
    search1: null,
    search2: null,
    accessList: [],
    entityAccess: {
      enityId: null,
      documentTypes: []
    }
  }),

  created() {
    var userId = this.$route.params["userId"]
    this.setBreadcrumb([
      {
        text: 'Admin'
      },
      {
        text: 'Document Access'
      },
      {
        text: 'Grant Access'
      },
    ]);
    if (userId == undefined) {
      const s = (data) => {
        this.users = data;
        this.userTableData.dataSource = data;
        this.userTableData.isLoading = false;
      };

      const e = (msg) => {
        console.log(msg);
        this.makeToast(msg);
      };
      api.getAllUsers(s, e);

    } else {
      this.getUserAccess(userId)
      console.log(userId)
    }
    this.getEntities();

    this.documentList = [{text: 'Purchase Order', value: 'PurchaseOrder', entity: "vendor"},
      {text: 'Customer Order', value: 'CustomerOrder', entity: "customer"},
      {text: 'Load Confirmation', value: 'LoadConfirmation', entity: "vendor"},
    ]

  },
  methods: {
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),

    getUserAccess(userId) {
      const s = (data) => {
        this.accessList = data;
      };

      const e = (msg) => {
        console.log(msg);
        this.makeToast(msg);
      };
      api.DocumentGetUserAccess(userId, s, e);
    },

    submit() {

      if (!this.completed) {
        return false; // stop here if form is invalid
      }
      const s = () => {
        this.$router.push({name: "ManageDocumentAccess"})

      };

      const e = (msg) => {
        console.log("Show Error")

        console.log("Error message", msg);
        this.makeToast(msg)

      };
      var access = {
        userId: this.user.id,
        entityId: this.selectedEntity.id,
        documentTypes: this.selectedDocuments
      }
      // console.log("this.userData", this.userData);
      api.DocumentApplyAccess(access, s, e);

    },
    selectEntity(entity) {
      this.selectedEntity = entity
      console.log("Selected Entity:", entity)
    },
    goBackToSearch() {
      this.$router.back()
    },
    goBackToUserSelect() {
      this.user = null
    },
    changeDocumentList() {

      this.entityList = this.entity[this.entityType.toLowerCase()]
      this.entityTableData.dataSource = this.entityList

      this.entityDocumentList = this.documentList.filter((x) =>
          x.entity.toLowerCase() === (this.entityType.toLowerCase())
      );

      this.selectedDocuments = []
      this.selectedEntity = null
    },
    makeToast(message) {
      this.$bvToast.toast(`${message}`, {
        title: 'Something went wrong.',
        autoHideDelay: 5000,
        appendToast: false
      })
    },

    validateState(name) {
      // console.log(name)
      // console.log(this.$v.user[name])
      const {$dirty, $error} = this.$v.userData[name];
      return $dirty ? !$error : null;
    },

    getEntities() {
      api.GetVendors(s => {
        this.entity.vendor = s
        this.changeDocumentList();
      }, console.error)
      api.GetCustomers(s => {
        this.entity.customer = s
        this.changeDocumentList();
      }, console.error)

      this.entityTableData.isLoading = false
    },
    setUser(user) {
      this.user = user
    },
    searchUser() {
      this.userTableData.dataSource = this.users.filter((x) =>
          x.name.toLowerCase().includes(this.search1.toLowerCase())
          || x.surname.toLowerCase().includes(this.search1.toLowerCase())
          || x.email.toLowerCase().includes(this.search1.toLowerCase())
      );

    },
    searchEntity() {
      this.entityTableData.dataSource = this.entityList.filter((x) =>
          x.name.toLowerCase().includes(this.entitySearch.toLowerCase())
          || x.code.toLowerCase().includes(this.entitySearch.toLowerCase())
      );
    },

  },
  computed: {
    userTableRows() {
      return this.userTableData.dataSource.length
    },
    entityTableRows() {
      return this.entityTableData.dataSource.length
    },
    completed() {
      return this.user !== null && this.entityType !== null && this.selectedEntity !== null
    }
  },
  watch: {
    entityType() {
      this.changeDocumentList()
    },
    "accessList": function (newValue) {
      console.log((newValue))
      for (var value in newValue) {
        console.log(newValue[value]);
        if (newValue[value].count == 0) {
          (this.entityList.find(x => x.id == value))["_rowVariant"] = "";
        } else {
          (this.entityList.find(x => x.id == value))["_rowVariant"] = "info";
        }

        console.log(this.entityList)
      }
    }
  }
}
</script>

<style scoped>

</style>